<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            /
            <router-link :to="{name: 'admin-master' }" class="root">
                Admin Master
            </router-link>
            <span class="child">/ Series </span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>Series</span>
            </v-card-title>
            <v-card-text>
                <div class="add-section">
                    <div style="text-align: center;">
                        <v-col>
                            <v-form ref="form">
                            <label class="add-text">Add New Series</label>
                            <v-text-field class="text" v-model="phyname" outlined dense :rules="[rules.required]" clearable required></v-text-field>                           
                                    <label class="add-text">Display Order</label>
                                    <v-text-field class="text" v-model="display_order" outlined dense  clearable required></v-text-field>                               
                                    <label class="add-text">Library Departmnt</label>
                                    <v-autocomplete
                                     dense
                                    v-model="editedlibraryDept"
                                    :items="libDept_list"
                                    item-value="libDepartment"
                                    item-text="libDepartment"
                                    persistent-hint
                                    outlined
                                    ></v-autocomplete> 
                                     <label class="add-text">Book Type</label>
                                    <v-autocomplete
                                    dense
                                    v-model="editedbookType"
                                    :items="bookType_list"
                                    item-value="bookType"
                                    item-text="bookType"
                                    persistent-hint
                                    outlined
                                    ></v-autocomplete>    
                                     <label class="add-text">Book Classification</label>
                                    <v-autocomplete
                                    dense
                                    v-model="editedbookCL"
                                    :items="bookCl_list"
                                    item-value="bookClId"
                                    item-text="bookClName"
                                    persistent-hint
                                    outlined
                                    ></v-autocomplete>        
                                    <label class="add-text">Short Name</label>
                                    <v-text-field class="text" v-model="short_name" outlined dense  clearable required></v-text-field>
                            <v-btn color="success" @click="addSeries">Add</v-btn>
                            </v-form>
                        </v-col>
                    </div>
                </div>

                <template>
                    <div class="text-center">
                        <v-dialog v-model="dialog" width="500">
                            <v-card>
                                <v-card-title class="grey lighten-2">
                                    Edit Series
                                </v-card-title>

                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">Series</label>
                                    <v-text-field class="text" v-model="phyname" outlined dense :rules="[rules.required]" clearable required></v-text-field>
                                
                                 <label class="add-text">Library Departmnt</label>
                                    <v-select
                                    dense
                                    v-model="editedlibraryDept"
                                    :items="libDept_list"
                                    item-value="libDepartment"
                                    item-text="libDepartment"
                                    persistent-hint
                                    outlined
                                    ></v-select> 
                                     <label class="add-text">Book Type</label>
                                    <v-select
                                    dense
                                    v-model="editedbookType"
                                    :items="bookType_list"
                                    item-value="bookType"
                                    item-text="bookType"
                                    persistent-hint
                                    outlined
                                    ></v-select> 
                                     <label class="add-text">Book Classification</label>
                                    <v-select
                                    dense
                                    v-model="editedbookCL"
                                    :items="bookCl_list"
                                    item-value="bookClId"
                                    item-text="bookClName"
                                    persistent-hint
                                    outlined
                                    ></v-select> 
</v-card-text>
                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">Display Order</label>
                                    <v-text-field class="text" v-model="display_order" outlined dense  clearable required></v-text-field>
                                </v-card-text>
                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">Short Name</label>
                                    <v-text-field class="text" v-model="short_name" outlined dense clearable required></v-text-field>
                                </v-card-text>

                                <!-- <v-divider></v-divider> -->

                                <div class="text-center">
                                    <v-spacer></v-spacer>
                                    <v-btn class="m-3" @click="dialog = false">
                                        Close
                                    </v-btn>
                                    <v-btn class="m-3" @click="saveEdit()">Update</v-btn>
                                </div>
                            </v-card>
                        </v-dialog>
                    </div>
                </template>

                <template v-if="Physical_details_list!=null">
                    <v-data-table :headers="headers" :items="Physical_details_list" :search="search" class="elevation-1 nowrap">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line hide-details></v-text-field>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.edit="{ item }">
                            <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                            <v-icon v-if="item" class="mr-2" @click="updatePhysicalDetails(item)">mdi mdi-pencil-box</v-icon>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                            <v-switch v-model="item.seriesIsactive" @change="editItem(item)"></v-switch>
                        </template>
                        <template v-slot:item.delete="{ item }">
                            <v-icon v-if="item" class="mr-2" @click="deleteSeries(item)"> mdi mdi-delete</v-icon>
                        </template> 
                    </v-data-table>
                </template>
            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            overlay:false,
            snackbar_msg: "",
            snackbar: false,
            color: "",
            org: null,
            dialog: false,
            search: "",
            phyname:null,  
            phyId:null,      
            display_order:null,
            short_name:null,
            userType: null,
            edit_btn_load: false,
            editUserType: null,
            editUserTypeId: null,
            editedbookCL:null,
            bookType_list : null,
            bookCl_list:null,
            libDept_list : null,
            rules: {
                required: (value) => !!value || "Required.",
            },
            Physical_details_list: null,
            headers: [
                { text: "Sr no", value: "srno" },
                { text: "Name", value: "phyname" },
                { text: "Short Name", value: "short_name" },
                { text: "Library Department", value: "librarydepartment" },
                { text: "Book Type", value: "booktype" },
                { text: "Book Classification", value: "cl" },
                { text: "Edit", value: "edit" },
                { text: "Toggle", value: "actions", sortable: false },
                 { text: "Delete", value: "delete" },
            ],
        }),
        watch: {},
        methods: {
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            editItem(item) {
                //console.log(item);
                axios
                    .post("/admin/seriesIsactive", item)
                    .then((res) => {
                        if(res.data.msg == "200")                        
                        this.showSnackbar("#4CAF50", "Series updated successfully...");
                        else
                        this.showSnackbar("#b71c1c", "Serier Not Found,Please refresh and try again");
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        axios;
                    });
            },

            updatePhysicalDetails(item) {  
                
                this.dialog = true;
                this.phyId = item.physicaldetailsId                
                this.phyname = item.phyname;
                this.display_order = item.display_order;
                this.short_name = item.short_name    
                this.editedlibraryDept =  item.librarydepartment 
                this.editedbookType =  item.booktype
                this.editedbookCL = item.clid;
                console.log("cl");
                console.log(this.editedbookCL);
            },

            saveEdit() {
                if(this.phyname){
                this.phyname = this.phyname.trim()
                this.short_name = this.short_name.trim()
                }               
                const data = {
                    phyId: this.phyId,
                    phyname: this.phyname,
                    short_name: this.short_name,
                    display_order: this.display_order,    
                    librarydepartment : this.editedlibraryDept, 
                    bookType : this.editedbookType ,
                     bookCL : this.editedbookCL             
                };
                if (this.phyname) {
                    axios
                        .post("/admin/editSeries", data)
                        .then((res) => {
                            if (res.data.msg == "success") {
                            this.sec_mode_list = res.data.user_list;
                            this.showSnackbar("#4CAF50", "Series updated successfully...");                            
                            this.dialog = false;
                            } else {
                                this.showSnackbar("#b71c1c", res.data.msg);
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            console.log(error);                          
                        })
                        .finally(() => {
                            this.edit_btn_load = false;
                            this.onload();                            
                        });
                } else {
                    this.showSnackbar("#b71c1c", "Please enter Series");
                }
            },
            deleteSeries(item){                  
                axios
                    .post("/admin/deleteSeries", item)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.showSnackbar("#4CAF50", "Series Deleted Successfully  !!");
                            this.onload();
                        }else if(res.data.msg == "exp"){
                           this.showSnackbar("#b71c1c", "Can not be Deleted!!!");                          
                       }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        window.console.log(error);
                    });
            },

            onload() {
                this.overlay = true
                axios
                    .post("/admin/getSeries")
                    .then((res) => {
                        this.org = res.data.org;
                        this.Physical_details_list = res.data.physicaldetails;
                        this.libDept_list = res.data.libDept_list;
                        this.bookType_list = res.data.bookType_list;
                        this.bookCl_list = res.data.bookCl_list;
                        this.overlay = false
                        //this.showSnackbar("#4CAF50","Success")
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.overlay = false
                    });
            },

            addSeries() {
                const data = {
                    seriesName: this.phyname,
                    display_order: this.display_order,
                    short_name: this.short_name,
                    librarydepartment : this.editedlibraryDept, 
                    bookType : this.editedbookType,  
                    bookCL : this.editedbookCL
                };

                if (this.phyname) {
                    axios
                        .post("/admin/addSeries", data)
                        .then((res) => {
                            this.org = res.data.org;
                            //this.Physical_details_list = res.data.physicaldetails;
                            if (res.data.msg == "success") {
                                this.showSnackbar("#4CAF50", "Series added successfully...");
                                this.userType = null;
                                this.$refs.form.reset()
                                this.onload();
                            } else {
                                this.$refs.form.validate()
                                this.showSnackbar("#b71c1c", res.data.msg);
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            console.log(error);
                        })
                        .finally(() => {
                            this.onload();
                        });
                } else {
                    this.showSnackbar("#b71c1c", "Please enter Series");
                    this.$refs.form.validate()
                }
            },
        },
        mounted() {
            this.onload();
        },
    };
</script>
<style scoped>
    .heading {
        background-color: #3f51b5;
        padding: 0.3rem;
        color: white;
    }
    .v-icon {
        cursor: pointer;
    }
    .text >>> .v-input__slot {
        background: white !important;
        border-color: hsla(190, 99%, 30%, 1) !important;
    }
</style>